import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Buttons from './Buttons';
import Button from '@mui/material/Button';
import TypeIt from "typeit-react";
class App extends Component {



  render() {
    const choices = ['zee', 'zippy', 'zippybonzo', 'bisexual', 'a nerd', 'silly']; // Your array
    const randomIndex = Math.floor(Math.random() * choices.length); // Generate a random index
    const randomChoice = choices[randomIndex]; // Get the element at the random index
    return (
        <div className="App">
          <header className="App-header">
            <img
                src="https://cdn.discordapp.com/avatars/1125518069482139658/ddb1f7522e696e44c514971ddb91bce3.webp?size=4096"
                className="App-logo" alt="logo"/>
            <p>
              <TypeIt>Hey, I'm {randomChoice}</TypeIt>
            </p>
            <p>
              I do creative things sometimes :)
            </p>
          </header>
          <Buttons/>
        </div>
    );
  }
};
export default App;
