import React, { Component } from 'react';
import './App.css';
import { Button, ButtonGroup } from '@mui/material';

class Buttons extends Component {
    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <ButtonGroup variant="contained" aria-label="Buttons">
                        <Button variant="contained" href="/projects">
                            Projects
                        </Button>
                        <Button variant="contained" href="https://discord.gg/sfx">
                            Sounds Hub
                        </Button>
                    </ButtonGroup>
                </header>
            </div>
        );
    }
}

export default Buttons
